@import "_normalize";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  color: rgb(110, 110, 110);
}

a {
  text-decoration: none;
  color: white;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5vw 0 5vw 0;
  width: 100%;
  &__background {
    display: flex;
    background-image: url("../assets/img/foto-onas-xl.jpg");
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    @media (max-width: 900px) {
      background-image: url("../assets/img/foto-onas-sm.jpg");
    }
    &--second {
      background-image: url("../assets/img/foto-flota-xl.jpg");
      @media (max-width: 900px) {
        background-image: url("../assets/img/foto-flota-sm.jpg");
      }  
    }
  }
  &__context {
    width: 70%;
    font-size: 1.1rem;
    line-height: 3rem;
    margin: 2vw 0 3vw 0;
  }
  &__motto {
    color: white;
    font-size: 1.8rem;
    line-height: 5rem;
    padding: 0 15vw 0 15vw;
    @media (max-width: 1100px) {
      font-size: 1.5rem;
      line-height: 4rem;
    }
    @media (max-width: 750px) {
      font-size: 1.3rem;
      padding: 0 8vw 0 8vw;
    }
    @media (max-width: 550px) {
      line-height: 3rem;
    }
    @media(max-width: 380px){
      font-size: 1.2rem;
    } 
  }
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 12vw;
  background-color: #e4e9ec;
  margin: 3vw 0 3vw 0;
  &:hover {
    transform: scale(1.15);
    transition: 0.2s;
  }
  @media (max-width: 1100px) {
    display: flex;
    align-self: center;
    justify-self: center;
    height: 80%;
    width: 80%;
  }
}

.features {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 0 5vw 0 5vw;
  @media (max-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2vw;
    row-gap: 2vw;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(0, 118, 255);
  background: linear-gradient(34deg, rgba(0, 118, 255, 1) 11%, rgba(223, 77, 84, 1) 77%);
  width: 100%;
  height: 40vw;
  margin-bottom: 1vw;
  color: white;
  font-size: 1.5rem;
  &__title {
    color: #e6e6e6;
  }
  @media (max-width: 1100px) {
    font-size: 1.3rem;
  }
  @media (max-width: 650px) {
    height: 60vw;
  }
  @media (max-width: 425px) {
    height: 80vw;
    font-size: 1rem;
  }

  @media (max-width: 350px) {
    height: 100vw;
  }
}

.header {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    height: 100px;
    @media (max-width: 950px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 1.5rem;
    margin-top: 0;
    color: white;
    font-weight: 300;
    &--first {
      background-color: #DF4D54;
      text-align: center;
      width: 50%;
      @media (max-width: 950px) {
        width: 100%;
        margin: 0;
      }
  }
    @media (max-width: 410px) {
      font-size: 1.3rem;
    }
    &--second {
      width: 50%;
      height: 100px;
      background-color: #0076FF;
      @media (max-width: 950px) {
        width: 100%;
        margin: 0;
      }
    }
  }
  &__image {
    margin-top: 100px;
    width: 100%;
  }
  &__slant {
    height: 100px;
    margin-top: -16px;
    @media (max-width: 950px) {
      display: none;
    }
  }
}

.nav {
  display: flex;
  position: fixed;
  width: 100%;
  background-color: #fff;
  height: 100px;
  &__container {
    display: flex;
    width: 100%;
  }
  &__logo {
    position: fixed;
    display: flex;
    align-self: center;
    width: 200px;
    margin-left: 2%;
  }
  &__list {
    display: flex;
    margin-right: 15vh;
    list-style-type: none;
    @media (max-width: 900px) {
      display: none;
      flex-direction: column;
      &--visible {
        display: block;
        font-size: 16px;
        line-height: 2em;
        text-align: center;
        margin: 0;
        padding: 0;
        border: 0;
        list-style-position: inside;
        margin-top: 85px;
        flex-direction: column;
      }
    }
  }
  &__button {
    display: inline-block;
    padding: 15px 25px;
    border-radius: 4px;
    font-size: 1.125em;
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.9);
      width: 100vw;

    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    height: 40px;
    width: 70px;
    text-decoration: none;
    &:hover {
      transition: all 0.1s;
      color: #DF4D54;
    }
  }
  &__switcher {
    position: fixed;
    font-size: 2rem;
    background-color: white;
    line-height: 2em;
    border: 0;
    right: 65px;
    top: 2vh;
    cursor: pointer;
    @media (min-width: 901px) {
      display: none;
    }
  }
}

.offer {
  &__types {
    display: flex;
    justify-content: space-around;
    margin: 5vw 4vw 0 4vw;
    @media (max-width: 750px) {
      display: flex;
      flex-direction: column;
      margin: 5vw 8vw 0 8vw;
      align-items: center;
    }
  }
  &__list {
    list-style: url("../assets/img/list-check.png");
    margin: 0;
    padding: 1.4vw;
  }
  &__item {
    display: list-item;
    font-size: 1.2rem;
    line-height: 3rem;
  }
  &__type {
    display: flex;
    flex-direction: column;
    width: 30%;
    @media (max-width: 750px) {
      width: 100%;
    }
  }
  &__image {
    width: 120px;
    height: 120px;
    align-self: center;
    &:hover {
      transform: scale(1.15);
      transition: 0.2s;
    }
  }
}